import React from "react";
import Banner from "../../../components/Landers/Banner";
import Brands from "../../../components/Landers/Brands";
import Footer from "../../../components/Landers/Footer";
import GetStarted from "../../../components/Landers/GetStarted";
import Header from "../../../components/Landers/Header";
import styles from "../../../components/Landers/landers.module.scss";
import SectionTemplate from "../../../components/Landers/SectionTemplate";
import SEO from "../../../components/Landers/SEO";
import Testimonials from "../../../components/Landers/Testimonials";
import ThreeSteps from "../../../components/Landers/ThreeSteps";
import WhatsUnique from "../../../components/Landers/WhatsUnique";

const steps = [
  {
    h5: "Request ID Verification",
    p:
      "Initiate the background check on a candidate once they complete their personal profile. ",
  },
  {
    h5: "Run Instant Checks",
    p: "Screen the identity of the candidate based on the documents uploaded.",
  },
  {
    h5: "Screen the right candidate",
    p:
      "Keep tabs on the verification process with our real-time updates. Get notified if a candidate’s identity is verified or not.",
  },
];

const testimonials = [
  {
    name: "Rupali M",
    company: "Integrichain India Pvt. Ltd.",
    p: `The best part about SpringVerify is its user portal. It provides real-time updates making our HR job easier.
			The entire process is so seamless saving us a lot of time, effort and resources in the process.`,
  },
  {
    name: "Mariam M",
    company: "Touchkin eServices Pvt. Ltd.",
    p: `It's the first time we are doing background checks. But still, SpringVerify gave us very good clarity on the process.
			 They helped us in setting up the account quickly and connected us with the relevant contacts for any queries.`,
  },
  {
    name: "Maryam J",
    company: "Resolvit Management and Technology Consulting India Pvt. Ltd.",
    p: `SpringVerify is very simple, reliable, and straightforward. The UX is awesome, checks are fast and most importantly all
			 my queries are answered promptly without me having to wait for long.`,
  },
];

export default () => (
  <div className={`${styles.solutions} ${styles.id}`}>
    <SEO
      title="Instant Identity Verification Service  | SpringVerify India"
      desc="Verify your candidates identity with SpringVerify's instant background verification which provides results in seconds."
      url="https://in.springverify.com/screenings/identity-verification/"
      imgurl="https://assets-sv-in.gumlet.io/test-pages/SV-IN+(ID+verification).jpg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1="Verify your Candidate’s Identity Effortlessly"
        p={`Considered to be the fundamental of all checks - ID checks can help you rely on all other findings such as employment,
				education, reference, court checks, CV checks, and more.`}
        img="https://assets-sv-in.gumlet.io/LP/ID/banner.svg"
      />
    </div>
    <Brands />
    <section>
      <SectionTemplate
        img="ID/need.svg"
        h2="Importance of ID Verifications"
        p={`Identity checks help you understand if the candidate is who they claim they are. This way you can protect your company from the liability of hiring misidentified candidates. Thus, they can be super helpful for positions like:`}
        ul={[
          "Freelancers and external consultants",
          "Individuals handling sensitive company information",
          "Contractors in the gig economy",
          "Financial, healthcare, and hospitality sector",
        ]}
      />
    </section>
    <section>
      <SectionTemplate
        reversed
        img="ID/using.svg"
        h2="ID Verifications using SpringVerify"
        p="We verify the below proofs to confirm the identity of a candidate. The final report consists of verification details along with the status."
      >
        <div className={styles.using}>
          {["Voter ID", "PAN Card", "Driving License", "Aadhaar"].map(
            (e, i) => (
              <div>
                <img
                  data-src={`https://assets-sv-in.gumlet.io/LP/ID/Using/${
                    i + 1
                  }.svg`}
                  alt=""
                />
                <h6>{e}</h6>
              </div>
            )
          )}
        </div>
      </SectionTemplate>
    </section>
    <ThreeSteps
      steps={steps}
      candidate="https://assets-sv-in.gumlet.io/LP/ID/candidate.svg"
      path="ID"
    />
    <WhatsUnique
      path="ID"
      five={true}
      info={[
        "Safe and secure",
        "Faster turnaround times",
        "Easy integration and onboarding",
        "Powered by AI and Blockchain",
        "Workflow automation",
        "Transparent pricing",
        "Trusted by 1000+ companies",
        "Compliant with ISO, PCI, and more",
        "Robust & Flexible API",
        "Customized Digital Solutions",
      ]}
    />
    <Testimonials testimonials={testimonials} path="ID" />
    <GetStarted
      img="ID/get-started.svg"
      h3="Ready to get started with us?"
      p="SpringVerify's digital solutions are instant, reliable, and flexible built for your unique needs"
    />
    <Footer />
  </div>
);
